<template>
    <transition name="shareShow">
    <div class="comment" @click="$emit('videoMemberClose')">
        <transition name="commentShow">
            <div class="content" @click.stop v-show="videoMemberShow">
                <div class="top">
                    <div class="title flex-column">
                        <div class="heng"></div>
                        <ImgDecypt class="top-img" :src="topicInfo.author&&topicInfo.author.avatarUrl"/>
                        <p class="change">{{topicInfo.author&&topicInfo.author.nickName}}</p>
                        <p class="become">本视频为会员免费视频</p>
                        <p class="desc">今日你的免费观看次数已经用完</p>
                        <div class="btn flex-around">
                            <router-link tag="div" to="/promotionCode" class="btn-left">邀请赠VIP</router-link>
                            <div @click="toVip" class="btn-right">开通VIP</div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    </transition>
</template>
<script>
export default {
    props:{
        topicInfo:{//话题博主信息
            type:Object,
            default:() => {
                return {}
            }
        },
        videoMemberShow:{
            type:Boolean,
            default:false,
        }
    },
    methods: {
        toVip() {
            let vipGrap = {
              type: "video",
              vInfo: this.topicInfo
            }
            sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap))
            this.$router.push("/vipList");
        }
    }
}
</script>
<style lang="scss" scoped>
.comment{
    position: fixed;
    z-index: 2;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background:rgba($color: #000000, $alpha: 0.4);
}
.content{
    position: absolute;
    bottom:0;
    padding:0 16px;
    width:100%;
    background:#ffffff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-sizing: border-box;
}
.title{
    text-align: center;
    align-items: center;
    justify-content: center;
    padding:14px 0 10px;
}
.heng{
    display: inline-block;
    width:110px;
    height:6px;
    border-radius: 6px;
    background: #e0e5eb;
}
.top-img{
    width:46px;
    height:46px;
    margin-top:20px;
    border-radius: 50%;
    border:1px solid #F21313;
    overflow: hidden;
}
.change{
    margin-top:10px;
}
.become{
    margin-top:16px;
    color:$vermillion;
    font-size:20px;
}
.btn{
    width:100%;
    margin:48px 0 24px;
    height:36px;
    line-height:36px;
    color:#ffffff;
    font-size:14px;
}
.desc{
    font-size:14px;
    margin-top:20px;
}
.btn-left{
    width:120px;
    height:100%;
    background:linear-gradient(to right,#f518ec,#efe9a7);
    border-radius: 36px;
}
.btn-right{
    width:120px;
    height:100%;
    background:linear-gradient(to right,#ea2a2a,#f2b555);
    border-radius: 36px;
}
</style>