<template>
  <transition name="shareShow">
    <div class="comment" @click="$emit('videoPayClose')">
      <transition name="commentShow">
        <div class="content" @click.stop v-if="videoPayShow">
          <div class="top">
            <div class="title flex-column">
              <div class="heng"></div>
              <ImgDecypt :key="topicInfo.author&&topicInfo.author.avatarUrl" class="top-img" :src="topicInfo.author&&topicInfo.author.avatarUrl" />
              <p class="change">{{topicInfo.author&&topicInfo.author.nickName}}</p>
              <p class="become" v-show="!topicInfo.fansGift">看完整视频需要消耗</p>
              <p class="become" v-show="topicInfo.fansGift">本视频为粉丝团福利视频</p>
              <p class="become fans" v-show="topicInfo.fansGift">你当前不是粉丝团成员需要消耗金币</p>
              <div class="money">
                <div class="money-num flex-center">
                  <div class="money-left">{{(userInfo.vipType == 0 &&topicInfo.vipPriceType !=3 ) ?  topicInfo.price : topicInfo.vipPrice}}<span class="money-icon"></span></div>
                  <del class="money-right">{{(userInfo.vipType == 0 && topicInfo.vipPriceType !=3 )? `会员最低折扣价${topicInfo.vipPrice}` :`原价${topicInfo.price}` }}</del>
                </div>
                <div class="quan">
                  <div class="item flex-between" v-for="item in videoTickets" :key="item.gold">
                    <div class="item-left">视频{{item.gold}}金币观影券</div>
                    <div class="item-right">
                      <span class="have">剩余&ensp;{{item.count}}</span>
                      <input class="choose-input" type="checkbox" @click="select($event,item.gold)">
                    </div>
                  </div>
                </div>
              </div>
              <p class="become" v-show="topicInfo.fansGift">现在加入‘{{topicInfo.author&&topicInfo.author.nickName}}’可免费看所有福利视频</p>
              <div class="btn" @click="payVideo" v-show="!topicInfo.fansGift">确认支付</div>
              <div class="fans-btn flex-between" v-show="topicInfo.fansGift">
                <div class="fans-left" @click="$emit('joinFans')">加入粉丝团</div>
                <div class="fans-left" @click="payVideo">购买视频</div>
              </div>
              <div class="money-bottom">*作者原创不易，给个鼓励上传更多优秀作品</div>
            </div>
          </div>
        </div>
      </transition>
      <van-overlay :show="loading" class="loading flex-center">
        <van-loading type="spinner" color="#f21313" size="24px" vertical>正在支付，请稍等</van-loading>
      </van-overlay>
    </div>
  </transition>
</template>
<script>
import { payVideo } from "@/api/app.js";
import { mapGetters } from "vuex";
export default {
  props: {
    topicInfo: {
      //话题博主信息
      type: Object,
      default: () => {
        return {};
      },
    },
    videoPayShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      videoTickets: JSON.parse(sessionStorage.getItem("userInfo")).videoTickets, //观影券列表
      payQuan: "", //观影卷价格
      loading: false, //支付接口loading
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },
  methods: {
    async payVideo() {
      //点击支付视频按钮
      let req = {
        mediaId: this.topicInfo.id,
        ticketValue: this.payQuan || 0,
      };
      this.loading = true;
      let ret = await this.$Api(payVideo, req);
      this.loading = false;
      if (ret.code == 200) {
        this.$toast("视频购买成功");
        this.$emit("paySuccess", this.payQuan);
        this.$bus.$emit("paySuccess", this.payQuan);
        if (this.payQuan) {
          this.$store.dispatch("user/getUserInfo");
        }
      } else if (ret.code == 6010) {
        this.$toast(ret.tip || "余额不足");
        let vipGrap = {
          type: "video",
          vInfo: this.topicInfo,
        }
        sessionStorage.setItem('vipGrap', JSON.stringify(vipGrap));
        this.$router.push("/wallet"); //用户余额不足直接跳金币钱包
      } else {
        this.$toast(ret.tip || "购买失败");
      }
    },
    select(e, num) {
      if (e.target.checked) {
        var quan = document.getElementsByClassName("choose-input");
        for (let i = 0; i < quan.length; i++) {
          quan[i].checked = false;
        }
        e.target.checked = true;
        this.payQuan = num;
      } else {
        this.payQuan = "";
      }
    },
  },
  watch: {
    videoPayShow(newval) {
      if (newval) {
        this.videoTickets = JSON.parse(
          sessionStorage.getItem("userInfo")
        ).videoTickets; //观影券列表
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.comment {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.4);
}
.content {
  position: absolute;
  bottom: 0;
  padding: 0 16px;
  width: 100%;
  background: #ffffff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-sizing: border-box;
}
.title {
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px 0 6px;
}
.heng {
  display: inline-block;
  width: 110px;
  height: 6px;
  border-radius: 6px;
  background: #e0e5eb;
}
.top-img {
  width: 46px;
  height: 46px;
  margin-top: 20px;
  border-radius: 50%;
  border: 1px solid #f21313;
  overflow: hidden;
}
.change {
  margin-top: 10px;
}
.become {
  margin-top: 16px;
}
.btn {
  width: 100%;
  margin-top: 20px;
  height: 30px;
  border-radius: 30px;
  line-height: 30px;
  color: #ffffff;
  background: $vermillion;
  font-size: 14px;
}
.money-left {
  color: $vermillion;
  font-size: 34px;
}
.money-icon {
  display: inline-block;
  width: 21px;
  height: 21px;
  background: url("../../assets/png/money_icon.png") center center no-repeat;
  background-size: 100%;
  margin-left: 2px;
  vertical-align: bottom;
}
.money-num {
  align-items: baseline;
  margin-top: 10px;
}
.money-right {
  margin-left: 26px;
}
.money-bottom {
  width: 100%;
  margin-top: 14px;
  color: #939393;
  font-size: 10px;
  padding: 6px 0;
  border-top: 1px solid #efefef;
}
.money {
  width: 100%;
}
.quan {
  min-height: 80px;
  max-height: 170px;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 20px;
}
.item {
  padding: 10px 14px;
  border-bottom: 1px solid #efefef;
  align-items: center;
}
.item:nth-child(1) {
  border-top: 1px solid #efefef;
}
.have {
  margin-right: 30px;
}
.choose-input {
  vertical-align: middle;
  background: none;
  outline: none;
  border: none;
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 18px;
  height: 16px;
  position: relative;
}

input[type="checkbox"]:after {
  position: absolute;
  width: 10px;
  height: 12px;
  top: 0;
  content: " ";
  border: 3px solid $vermillion;
  color: #fff;
  display: inline-block;
  visibility: visible;
  padding: 0px 1px;
  border-radius: 3px;
}

input[type="checkbox"]:checked:after {
  content: "✓";
  font-size: 10px;
  background: $vermillion;
}
.fans {
  font-size: 14px;
  color: $vermillion;
}
.fans-btn {
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
}
.fans-left {
  width: 132px;
  height: 36px;
  border-radius: 23px;
  text-align: center;
  line-height: 36px;
  background: $vermillion;
}
</style>